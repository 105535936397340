// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 id="table-of-contents">Table of contents:</h4>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#cultivating-trust-and-psychological-safety"
        }}>{`Cultivating Trust and Psychological Safety`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#fostering-a-culture-of-continuous-growth"
        }}>{`Fostering a Culture of Continuous Growth`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#empowering-ownership-and-autonomy"
        }}>{`Empowering Ownership and Autonomy`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#promoting-diversity-and-inclusion"
        }}>{`Promoting Diversity and Inclusion`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#balancing-technical-and-interpersonal-skills"
        }}>{`Balancing Technical and Interpersonal Skills`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#nurturing-innovation-and-creativity"
        }}>{`Nurturing Innovation and Creativity`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#conclusion"
        }}>{`Conclusion`}</a></li>
    </ol>
    <h2 id="introduction">
  Introduction
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`One of the Engineering managers goals is to unlock the full potential of their team and be a factor
of inspirations and/or at least a facilitator to achieve extraordinary results. Beyond just managing
the technical side of things of a projects and tasks, our role involve nurturing talent, fostering
innovation, and creating an environment that cultivates growth and success.`}</p>
    <p>{`In this blog post, I will quickly explore with you some important key aspects of effective engineering management
that can empower teams and guide them towards greatness.`}</p>
    <h2 id="cultivating-trust-and-psychological-safety">
  Cultivating Trust and Psychological Safety
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p><strong parentName="p">{`Trust is the cornerstone of any high-performing team.`}</strong></p>
    <p>{`Engineering managers can foster trust by actively listening to team members, transparent communication,
and promoting a psychologically safe environment. Sharing personal anecdotes and success stories can highlight
the impact of trust on collaboration and productivity. Create an environment where team members
feel safe to voice their opinions, ask questions, and share concerns without fear of judgment.`}</p>
    <p>{`I personally think that it is important to model the behaviour you want to see in your team.
Demonstrate authenticity, trustworthiness and vulnerability in your own interactions is fine and will probably
just encourage the team member to reciprocate.`}</p>
    <p>{`Cultivate trust and psychological safety is an ongoing process, it requires consistent efforts,
active communication, and a genuine commitment to the well-being and growth of your team.`}</p>
    <h2 id="fostering-a-culture-of-continuous-growth">
  Fostering a Culture of Continuous Growth
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p><strong parentName="p">{`Developing a growth mindset is vital for individual and team success.`}</strong></p>
    <p>{`Explore strategies to cultivate a growth mindset within your engineering team, such as providing constructive feedback, encouraging continuous learning. An another important point is to create a safe space where failure is seen as an opportunity for growth, rather than something to be feared.`}</p>
    <p>{`Encourage individuals to embrace challenges, with persistency and consistency whithout forgetting to acknowledge and publicly recognize individuals who shown significant progress or made valuable contributions to the team.`}</p>
    <h2 id="empowering-ownership-and-autonomy">
  Empowering Ownership and Autonomy
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p><strong parentName="p">{`Empowering team members to take ownership of their work can be a powerful motivator.`}</strong></p>
    <p>{`Encourage decision-making, and provide autonomy within the team by delegating responsibility according to individual skills and interests.`}</p>
    <p>{`Giving individuals the freedom to contribute and make meaningful decisions, can easily unlock team member potential and fuel their creativity. People will start feeling more valued by nature because of the impact they are making in their team and at a company level hence more motivated and engaged to keep delivering successful initiatives.`}</p>
    <h2 id="promoting-diversity-and-inclusion">
  Promoting Diversity and Inclusion
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p><strong parentName="p">{`Diversity and inclusion are essential for driving innovation and success.`}</strong></p>
    <p>{`Recognize and value the unique perspectives and experiences that each team member brings.
Encourage open and respectful communication, allowing different viewpoints to be heard and considered`}</p>
    <p>{`Explore strategies for creating a diverse and inclusive engineering team, such as implementing unconscious bias training, establishing mentorship programs, and fostering an environment where everyone feels valued.`}</p>
    <p>{`Emphasize the importance of diverse perspectives in problem-solving and decision-making as it leads to stronger teams, increased engagement, and better outcomes on the engineering projects.`}</p>
    <h2 id="balancing-technical-and-interpersonal-skills">
  Balancing Technical and Interpersonal Skills
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p><strong parentName="p">{`Engineering managers often face the challenge of balancing technical expertise with soft skills.`}</strong></p>
    <p>{`Discuss the significance of developing both technical and interpersonal capabilities within engineering teams.
One potential way to achieve that is to encourage cross-functional collaboration across the company, departments or teams.`}</p>
    <h2 id="nurturing-innovation-and-creativity">
  Nurturing Innovation and Creativity
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p><strong parentName="p">{`Innovation is the lifeblood of engineering teams.`}</strong></p>
    <p>{`Explore ways to nurture a culture of innovation and creativity, such as organizing brainstorming sessions, encouraging experimentation, and providing dedicated time for research and development. Share success stories that demonstrate the impact of innovative ideas on real-world outcomes.`}</p>
    <h2 id="conclusion">
  Conclusion
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`As an engineering manager with an engineering background, I can highly recommend you embracing trust cultivation.`}</p>
    <p>{`Looking back at my past experiences as an engineer, my best memories were all linked to a manager who was trusting the team, empowering ownership and autonomy, in an environment where the failures were sources of learnings.`}</p>
    <p>{`The impact on the people's life that you are working with, should be always the top priority and not just a small factor on the equation of a team success. As far as you keep that in mind, the success and all the good things will always be next to you.`}</p>
    <p>{`Try a few things mentioned above in this post or all of them :) and feel free to drop me a message if you need some advices or suggestions on your current problematic. I will be more than happy to share with you some experience if it can help you.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   